import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  GridFlex,
  Title,
  GridBox,
  Container,
} from '@components';

import Img from '../../Img';
import theme from '../../styled/theme';

const StyledImg = styled(Img)`
  border-radius: 5%;
  border: 1px solid ${theme.colors.textLight};
`;

const Gallery = ({ visible, title, photos }) => {
  if (!visible) return null;
  return (
    <Container>
      <GridBox
        width={1}
        mb={[1, 2, 3, 4]}
        textAlign="center"
      >
        <Title
          color="yellow"
          tag="h4"
        >
          {title}
        </Title>
      </GridBox>
      <GridFlex>
        {photos.map(({ image }) => (
          <GridBox
            px={[2, 3, 4]}
            key={`${image.alt}-${image.src}`}
            width={[1, 1 / 2, 1 / 4]}
          >
            <StyledImg {...image} />
          </GridBox>
        ))}
      </GridFlex>
    </Container>
  );
};

Gallery.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object.isRequired,
  }),
  photos: PropTypes.arrayOf(PropTypes.shape({
    alt: PropTypes.string,
    src: PropTypes.string,
  })),
  title: PropTypes.string,
  visible: PropTypes.bool,
};

export default Gallery;
