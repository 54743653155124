import React from 'react';
import PropTypes from 'prop-types';
import {
  GridFlex,
  Title,
  Box,
  Container,
  Markdown,
  Img,
} from '@components';

const Sections = ({ sections }) => {
  if (!sections?.length) return null;

  return (
  // eslint-disable-next-line complexity
    sections.map(({ first, second, color }, index) => {
      const { image } = first;
      const isEven = index % 2 === 0;
      return (
        <Container key={`${first.title || second.title}-${first.image.alt}-${first.image.src}`}>
          <GridFlex alignItems={image ? 'center' : 'initial'}>
            <Box
              width={[1, 1, 1 / 2]}
              order={[1, 1, isEven ? 0 : 1]}
              py={[2, 2, 0]}
              pl={!isEven ? [2, 3, 4] : null}
              pr={isEven ? [2, 3, 4] : null}
            >
              {image
                ? <Img {...image} maxHeight={500} />
                : (
                  <>
                    <Title
                      tag="h4"
                      color="yellow"
                      mb={[1, 2, 3, 4]}
                    >
                      {first.title}
                    </Title>
                    <div>{first.text}</div>
                  </>
                )}
            </Box>
            <Box
              width={[1, 1, 1 / 2]}
              pl={isEven ? [1, 2, 3] : null}
              pr={!isEven ? [1, 2, 3] : null}
              textAlign={['center', 'center', 'initial']}
            >
              <Title
                tag="h4"
                color={color}
              >
                {second.title}
              </Title>
              <Markdown>{second.markdown}</Markdown>
            </Box>
          </GridFlex>
        </Container>
      );
    })
  );
};

Sections.propTypes = {
  sections: PropTypes.arrayOf(PropTypes.shape({})),
};

export default Sections;
