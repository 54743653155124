import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  GridFlex,
  Container,
  Box,
  Title,
  Img,
  Markdown,
  theme,
} from '@components';
import { getColorCycle } from '@helpers';

const ItemWrapper = styled.div`
  position: relative;
  ${(props) => props.isEven && `
    background: ${theme.colors.background.blue};
  `}
`;

const OfferItems = ({ items, evenStartsWith = 0 }) => {
  if (!items?.length) return null;

  return items.map(({
    title,
    markdown,
    additional,
    image,
    id,
  }, index) => {
    const isEven = index % 2 === evenStartsWith;
    return (
      <ItemWrapper
        key={title}
        isEven={isEven}
        id={id}
      >
        <Container>
          <GridFlex alignItems="center" py={[2, 3, 4]}>
            {image && (
              <Box
                width={[1, 1, 1 / 2]}
                order={[1, 1, isEven ? 0 : 1]}
              >
                <Img {...image} />
              </Box>
            )}
            <Box
              width={image ? [1, 1, 1 / 2] : 1}
              maxWidth={!image ? theme.widths.small : undefined}
              mx={!image ? 'auto' : 0}
              py={[1, 2, 3]}
              order={[0, 0, isEven ? 1 : 0]}
              textAlign={['center', 'center', 'initial']}
            >
              <Title
                tag="h4"
                color={getColorCycle(index)}
                mb={[1, 2, 4]}
              >
                {title}
              </Title>
              <Markdown>
                {markdown}
              </Markdown>
              {additional && (
                <Box mt={[1, 2, 3]}>
                  {additional.map((info) => (
                    <div key={`${title}-${info.title}`}>
                      <strong>
                        {info.title}
                        {': '}
                      </strong>
                      {info.text}
                    </div>
                  ))}
                </Box>
              )}
            </Box>
          </GridFlex>
        </Container>
      </ItemWrapper>
    );
  });
};

OfferItems.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object.isRequired,
  }),
  location: PropTypes.object.isRequired,
};

export default OfferItems;
