import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import {
  Hero,
  Box,
  Container,
  GridFlex,
} from '@components';
import {
  WhyUs,
  Offers,
} from '../components/PageTemplates/AboutPage';

const TextContent = (boxProps) => (
  <Container>
    <GridFlex py={[3, 4]}>
      <Box
        mx="auto"
        width={[1, 1, 1 / 2]}
        {...boxProps}
      />
    </GridFlex>
  </Container>
);

const VertykalnaKidsPage = ({ data }) => {
  const { markdownRemark: { frontmatter = {}, html } } = data;

  const {
    hero,
    whyUsMore,
    seo,
    offers,
    subhero,
  } = frontmatter;

  return (
    <>
      <Hero {...hero} seo={seo} />

      <TextContent
        fontSize={[0, 1, 2, 3]}
        width={[1, 1, 2 / 3]}
        py={0}
      >
        <div dangerouslySetInnerHTML={{ __html: subhero.markdown }} />
      </TextContent>

      <WhyUs {...whyUsMore} />
      <Offers offers={offers} evenStartsWith={1} />

      <TextContent>
        <div dangerouslySetInnerHTML={{ __html: html }} />
      </TextContent>
    </>
  );
};

VertykalnaKidsPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object.isRequired,
  }),
};

export default VertykalnaKidsPage;

export const vertykalnaKidsPageQuery = graphql`
  query VertykalnaKidsPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        hero {
          title
          markdown
        }
        subhero {
          markdown
        }
        whyUsMore {
          title
          items {
            title
            markdown
          }
        }
        offers {
          title {
            text
            color
          }
          markdown
          items {
            title
            markdown
            id
            image {
              alt
              src {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid_tracedSVG
                  }
                }
              }
            }
          }
        }
        seo {
          metaTitle
          metaDescription
        }
      }
    }
  }
`;
