import React from 'react';
import PropTypes from 'prop-types';
import {
  GridFlex,
  Title,
  Box,
  Container,
} from '@components';
import OfferItems from './OfferItems';

const Offers = ({ offers }) => {
  if (!offers?.length) return null;

  return (
    offers.map(({ items, title, markdown }) => (
      <>
        {(title || markdown) && (
          <Container>
            <GridFlex py={[3, 4]}>
              <Box
                width={1}
                textAlign="center"
              >
                {title?.text && (
                  <Title
                    color={title.color}
                    tag="h3"
                  >
                    {title.text}
                  </Title>
                )}
                {markdown && <div dangerouslySetInnerHTML={{ __html: markdown }} />}
              </Box>
            </GridFlex>
          </Container>
        )}
        <OfferItems items={items} />
      </>
    ))
  );
};
Offers.propTypes = {
  title: {
    color: PropTypes.oneOf(['red', 'blue', 'yellow']),
    text: PropTypes.string,
  },
  markdown: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape({})),
};
export default Offers;
