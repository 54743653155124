import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  GridFlex,
  Title,
  Box,
  Container,
} from '@components';

import theme from '../../styled/theme';
import Markdown from '../../Markdown';

const Wrapper = styled.div`
  background: ${theme.colors.background.blue};
`;

const Paragraph = styled(Markdown)`
  color: ${theme.colors.textLight};
`;

const WhyUs = ({ title, items }) => (
  <Wrapper>
    <Container>
      <GridFlex py={[3, 4]}>
        <Box
          width={1}
          textAlign="center"
        >
          <Title
            color="blue"
            tag="h3"
          >
            {title}
          </Title>
        </Box>
        <Box>
          <GridFlex>
            {items.map((item, index) => {
              const isEven = index % 2 === 0;
              return (
                <Box
                  key={item.title}
                  width={[1, 1, 1 / 2]}
                  textAlign={['center', 'center', isEven ? 'right' : 'left']}
                  px={null}
                  py={[2, 3, 4]}
                  pl={[0, 0, isEven ? 0 : 4]}
                  pr={[0, 0, isEven ? 4 : 0]}
                >
                  <strong>{item.title}</strong>
                  <Paragraph>{item.markdown}</Paragraph>
                </Box>
              );
            })}
          </GridFlex>
        </Box>
      </GridFlex>
    </Container>
  </Wrapper>
);

WhyUs.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object.isRequired,
  }),
  items: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    markdown: PropTypes.string,
  })),
  title: PropTypes.string,
};

export default WhyUs;
